.btn,
input[type=submit] {
  border-radius: 4px;
  border: 1px solid rgb(var(--btn));
}

.intro-section .btn {
  border-color: rgb(var(--slider_btn));
}

#footer .btn {
  border-color: rgb(var(--footer_btn));
}

.smile-gallery-block .btn.btn-warning.apptButton {
  background-color: transparent;
  border: 1px solid rgb(var(--btn_hover));
  border-radius: 4px;
}

.btn:hover,
input[type=submit]:hover {
  background-color: transparent;
  border-color: rgb(var(--btn_hover));
  border-radius: 4px;
}

.intro-section .btn:hover {
  background-color: transparent;
  border-color: rgb(var(--slider_btn_hover));
}

#footer .btn:hover {
  background-color: transparent;
  border-color: rgb(var(--footer_btn_hover));
}

.smile-gallery-block .btn.btn-warning.apptButton:is(:hover,:active,:focus) {
  background-color: rgb(var(--btn));
  border-color: rgb(var(--btn));
  color: rgb(var(--btn_font));
}